<template>
  <van-popup v-model="popupVisible" position="bottom">
    <van-search v-model="searchValue" @search="handleSearch" placeholder="请输入搜索关键词" />
    <van-picker
      show-toolbar
      :value-key="valueKey"
      :columns="newColums"
      @cancel="cancel"
      @confirm="confirm"
    >
   
    </van-picker>
  </van-popup>
</template>

<script>  
import { Popup, Picker,Search } from "vant";
export default {
  name: "pickerWithPopup",
  props: {
    valueKey: {
      type: String,
      default: "",
    },
    columns: {
      type:Array,
      default(){
        return []
      }
    }
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Search.name]: Search,
  },
  watch:{
    columns(vals){
      this.newColums = vals
    },
  },
  data() {
    return {
      searchValue:'',
      newColums:[],
      popupVisible: false,
    };
  },
  methods: {
    handleSearch(value){
      const arr = this.columns.filter(item => new RegExp(value,"g").test(item.itemName))
      this.newColums = arr
    },
    open() {
      this.popupVisible = true;
    },
    close() {
      this.popupVisible = false;
    },
    confirm(obj, index) {
      this.$emit("confirm", obj, index);
    },
    cancel() {
      this.popupVisible = false;
    },
  },
};
</script>

<style>
</style>